
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { FormInput, FormSelect, FormPhone, FormRadio } from "@/components/forms";
import { Getter } from "vuex-class";
import { Mandate } from "@/store/modules/mandate.store";
import { getMandateCandidates } from "@/utils/model-helpers";

interface VgeContact {
    phone: string;
    address: {
        street: string;
        number: string | null;
        bus: string | null;
        zip: string;
    };
}

@Component({
    components: {
        FormInput,
        FormSelect,
        FormPhone,
        FormRadio,
    },
})
export default class RequestKeyFormPart extends Vue {
    @Getter("activity/viewing") activity!: Activity;
    @Getter("currentEntityOffices") offices!: any[];
    @Getter("mandate/all") mandates!: Mandate[];

    @Prop() value?: VgeContact[] | null;

    @Prop({ default: false }) disabled?: boolean;

    officeOptions = [];

    selectedOfficeOrAddress = "";

    localValue: VgeContact = {
        phone: "",
        address: {
            street: "",
            number: "",
            bus: "",
            zip: "",
        },
    };

    get viewingMandate() {
        const mandate = this.mandates.find((mandate) => mandate.activity_id === this.activity.id);

        if (!mandate) {
            return;
        }

        return mandate;
    }

    get mandateCandidates() {
        if (!this.viewingMandate) {
            return [];
        }

        return getMandateCandidates(this.viewingMandate);
    }

    get addresses() {
        return [
            ...(this.offices ?? []).map((office) => ({
                label: office.name,
                value: `office_${office.id}`,
            })),
            ...this.mandateCandidates.map((candidate) => ({
                label: `${candidate.first_name} ${candidate.last_name}`,
                value: `candidate_${candidate.first_name}_${candidate.last_name}_${candidate.email}`,
            })),
            { label: "Ander adres", value: "other" },
        ];
    }

    handleOfficeOrOtherChanged() {
        if (!this.selectedOfficeOrAddress) {
            this.localValue = {
                phone: "",
                address: {
                    street: "",
                    number: "",
                    bus: "",
                    zip: "",
                },
            };
            return;
        }

        if (`${this.selectedOfficeOrAddress}`.startsWith("office_")) {
            const officeId = `${this.selectedOfficeOrAddress}`.replace("office_", "");
            const office = this.offices.find((office) => office.id === Number(officeId));
            if (office) {
                this.localValue = {
                    phone: office.phone,
                    address: {
                        street: office.address.street,
                        number: office.address.number,
                        bus: office.address.bus,
                        zip: office.address.zip,
                    },
                };
            }
            return;
        }

        if (`${this.selectedOfficeOrAddress}`.startsWith("candidate_")) {
            const candidateHandle = `${this.selectedOfficeOrAddress}`.replace("candidate_", "");
            const candidate = this.mandateCandidates.find((candidate) => `${candidate.first_name}_${candidate.last_name}_${candidate.email}` === candidateHandle);
            if (candidate) {
                this.localValue = {
                    phone: candidate.phone,
                    address: {
                        street: candidate.address.street,
                        number: candidate.address.number,
                        bus: candidate.address.bus,
                        zip: candidate.address.zip,
                    },
                };
            }
            return;
        }

        this.localValue = {
            phone: "",
            address: {
                street: "",
                number: "",
                bus: "",
                zip: "",
            },
        };
    }

    @Emit("input")
    handleInput() {
        return this.localValue;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: VgeContact | null) {
        this.localValue = newValue ?? {
            phone: "",
            address: {
                street: "",
                number: "",
                bus: "",
                zip: "",
            },
        };
    }
}
